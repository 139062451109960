<template>
    <b-modal ref="modal"

             @hidden="resetModal" id="member-lookup-modal" size="xl" v-bind:hide-footer="true" centered title="Search Members">
        <div class="card-body">
            <form ref="form" method="post" id="MemerSearchForm">
                <div class="row">
                    <div class="col-md-4">
                        <label><strong>Search by Member#</strong></label>
                        <div class="form-group">
                            <div class="search-left">
                                <label class="form-label"> Member #</label>
                                <input type="text" class="form-control" maxlength="20" name="memberLookupForm.member_id" v-model="memberLookupForm.member_id" id="searchbyname"  >
                                <span class="text-sm text-danger">{{ errors.member_id }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 text-center">
                        <strong>OR</strong>
                    </div>
                    <div class="col-md-12">
                        <label><strong>Search by Name</strong></label>
                    </div>
                    <div class="col-md-3">

                        <div class="form-group">
                            <label for="firstname" class="form-label">First Name</label>
                            <input type="text" class="form-control" maxlength="45" placeholder="Dickerson" name="memberLookupForm.first_name" v-model="memberLookupForm.first_name" value="">
                            <span class="text-sm text-danger">{{ errors.first_name }}</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="lastname" class="form-label">Last Name</label>
                            <input type="text" class="form-control" maxlength="45" placeholder="Macdonald" name="memberLookupForm.last_name" v-model="memberLookupForm.last_name">
                            <span class="text-sm text-danger">{{ errors.last_name }}</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="city" class="form-label">City</label>
                            <input type="text" class="form-control" maxlength="45" placeholder="New Alessiaview" name="memberLookupForm.city" v-model="memberLookupForm.city">
                            <span class="text-sm text-danger">{{ errors.city }}</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="state" class="form-label">State</label>
                            <select class="form-control" id="state" name="memberLookupForm.state" v-model="memberLookupForm.state">
                                <option value="">Please select</option>
                                <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.state }}</span>
                        </div>
                    </div>
                    <div class="col-md-12 modal-searchbtn text-center">
                        <div class="form-group">
                            <button type="button" @click="memberSearch();" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                        </div>
                        <div class="text-sm text-danger">{{ errors.not_found }}</div>
                    </div>
                </div>
            </form>
        </div>
        <div>
            <div v-if="memberSerchResultStatus == true" class="card-body primary-contact">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">Member#</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">email</th>
                        <th scope="col">City</th>
                        <th scope="col">State</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(member, r) in memberSearchResults" :key="r">
                        <th scope="row">{{ member.confirmation_code }}</th>
                        <td>{{ member.personal_details.first_name }}</td>
                        <td>{{ member.personal_details.last_name }}</td>
                        <td>{{ member.contact_details.email }}</td>
                        <td>{{ member.contact_details.city }}</td>
                        <td>{{ member.contact_details.state_code }}</td>
                        <td>{{ member.membership_status_text }}</td>
                        <td><button class="btn btn-primary btn-success" @click="pushSelectedMember(member)">Add</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';

const validateName = name => {
    if (name == "") {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateSelect = select => {
    if (select == undefined) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
export default {
    name: "MemberLookup",
    props: ['memberType','editContactType'],
    data(){
        return {
          lookup_url:null,
            memberLookupForm: {
                member_id: '',
                first_name: '',
                last_name: '',
                city: '',
                state: ''
            },
            button: {
                SearchButton: 'Search'
            },
            memberSerchResults: [],
            memberSerchResultStatus: false,
            primaryContact: {
                membership_id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone_1: '',
                address1:'',
                address2:'',
                city: '',
                state: '',
                state_code: '',
                zip: '',
                contact_type: '',
            },
            valid: false,
            success: false,
            errors: {},
            message: null,
        }
    },
    methods:{
        resetAlert() {
            this.displayAlert = false;
        },
        memberSearch: function () {
            if(!this.validate()) {
                return;
            }
            this.button.SearchButton = 'Searching...';
            this.errors= {};
            this.lookup_url = this.basePath+'api/member_lookup?member_id='+this.memberLookupForm.member_id+'&first_name='+this.memberLookupForm.first_name+'&last_name='+this.memberLookupForm.last_name+'&city='+this.memberLookupForm.city+'&state='+this.memberLookupForm.state;
          if (this.subdomain == 'shooting') {
             this.lookup_url = this.lookup_url + '&callfrom=clubroster';
          }
          if (this.subdomain == 'waterski') {
            this.lookup_url = this.lookup_url + '&editContactType='+this.editContactType;
          }
          if(this.memberType == 'guest'){
                this.lookup_url = this.lookup_url+'&member_type='+this.memberType;
            }
            axios.get(this.lookup_url)
                .then((res) => {
                    if(res.data.status == 'success') {
                        this.button.SearchButton = 'Search';
                        this.memberSerchResultStatus = true;

                        this.memberSearchResults = res.data.data;
                    } else {
                      if (res.data.errors == true) {
                        this.memberSerchResultStatus = false;
                        this.errors.not_found = res.data.message;
                      }else{
                        if(this.subdomain == 'shooting') {
                          this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active) member!';
                        }else {
                            if(this.memberType == 'guest')
                                this.errors.not_found = 'Officer needs to join as an active (Guest) member!';
                            else
                                this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                        }
                    }
                        this.button.SearchButton = 'Search';
                        return false;
                    }
                })
                .catch((error) => {
                  if(this.memberType == 'guest')
                    this.errors.not_found = 'Officer needs to join as an active (Guest) member!';
                else {
                    if (this.subdomain == 'shooting') {
                      this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active) member!';
                    } else {
                      this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                    }
                  }

                  this.button.SearchButton = 'Search';
                    return false;
                });
        },
        resetModal() {
            this.errors = {};
            this.memberSerchResultStatus = false;
            this.memberSearchResults = [];
            this.memberLookupForm = {};
          this.$emit('member-lookup-data', null);
        },
        pushSelectedMember: function(member){
            this.primaryContact.id = member.id;
            this.primaryContact.membership_id = member.confirmation_code;
            this.primaryContact.first_name = member.personal_details.first_name;
            this.primaryContact.last_name = member.personal_details.last_name;
            this.primaryContact.email = member.contact_details.email;
            this.primaryContact.phone_1 = member.contact_details.phone_1;
            this.primaryContact.address1 = member.contact_details.address1;
            this.primaryContact.address2 = member.contact_details.address2;
            this.primaryContact.city = member.contact_details.city;
            this.primaryContact.state = member.contact_details.state_code;
            this.primaryContact.state_code = member.contact_details.state_code;
            this.primaryContact.country = member.contact_details.country;
            this.primaryContact.zip = member.contact_details.zip;
            this.primaryContact.member = member;

            this.$emit('member-lookup-data', this.primaryContact);
            this.valid = true;
            this.$nextTick(() => {
                this.$bvModal.hide('member-lookup-modal')
            })
            this.errors.primary = '';
        },
        validate() {
            this.errors = {}
            const member_id = this.memberLookupForm.member_id;
            const first_name = this.memberLookupForm.first_name;
            const last_name = this.memberLookupForm.last_name;
            const city = this.memberLookupForm.city;
            const state = this.memberLookupForm.state;
            if((member_id == undefined || member_id === '' ) && (first_name == undefined || first_name == '') && (last_name == undefined || last_name=='' )&& (city == undefined ||city == '' ) && (state == undefined ||state =='' )) {
                this.errors.member_id = 'Please Enter Either Member Id or Below Member Details';
                this.valid = false;
                return this.valid;
            }else{
              this.valid = true;
            }
            if(member_id == undefined && (first_name == undefined || last_name == undefined || city == undefined || state == undefined)) {
                const validFirstName = validateName(this.memberLookupForm.first_name);
                this.errors.first_name = validFirstName.error;

                const validLastName = validateName(this.memberLookupForm.last_name);
                this.errors.last_name = validLastName.error;

                const validCity = validateSelect(this.memberLookupForm.city);
                this.errors.city = validCity.error;

                const validStateCode = validateSelect(this.memberLookupForm.state);
                this.errors.state = validStateCode.error;

                return this.valid;
            }

            this.valid = true;
            this.errors= {}

            return this.valid;
        }
    }
}
</script>

<style scoped>

</style>
